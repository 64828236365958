<template>
  <div class="page">
    <div class="create">
      <el-button
              plain
              type="text"
              size="mini"
              style="border: 1px solid #BCBCBC;
                        color: #303133;
                        padding: 0 11px;
                        width:70px;
                        margin:20px;
                        height: 30px;"
              @click="close"
              >返回</el-button
            >
      <div class="detil-code">添加片区</div>
    </div>
    <div class="box">
      <div class="tableRight">
        <el-form
          ref="ruleForm"
          class="inline:false"
          label-position="right"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <div class="title">
            <span class="titleName">行政地址</span>
              <div class="title_box" style="position: relative;left: -60px">
                <el-form-item label="省" prop="province" >
                  <el-select
                    v-model="form.province"
                    placeholder="请选择"
                    style="width:160px"
                    filterable
                   @change="getRoleIdsCity"
                  >
                    <el-option
                    v-for="item in roleArray_group"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="市" prop="city">
                  <el-select
                    v-model="form.city"
                    placeholder="请选择"
                    style="width:160px"
                    filterable
                    @change="getRoleIdsDistrict"
                  >
                    <el-option
                      v-for="item in roleArray_city"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="区/县" prop="district">
                  <el-select
                    v-model="form.district"
                    placeholder="请选择"
                    style="width:160px"
                    filterable
                    @change="getRoleIdsdassname"
                  >
                    <el-option
                      v-for="item in roleArray_area"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
<!--                <el-form-item label="街道/乡/镇" prop="town" v-if="activeName==0">-->
<!--                  <el-input v-model="form.town" placeholder="请输入街道/乡/镇" style="width: 300px"></el-input>-->
<!--                </el-form-item>-->
                <!-- <el-form-item label="所属部门" prop="addrDeptid">
                  <el-select v-model="form.addrDeptid" placeholder="请选择" filterable style="width:200px">
                    <el-option
                      v-for="(item, index) in streetList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
              </div>
          </div>
         
          <div class="title">
            <div class="titleName">片区信息</div>
            <!-- :style="{border:1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        width:70px;
                        height: 30px;}" -->
            <el-button type="primary" plain size="mini"
                :disabled='this.form.aaumAddrDTOs.length >= 10'
                :style="{background:'#ffffff',color: this.form.aaumAddrDTOs.length >= 10 ? '' : '#1082FF',}"
               @click="addIncreased" >新增片区</el-button>
            <el-button type="text" size="mini" @click="delIncreased" style="border: 1px solid #F52424;
                        color: #F52424;
                        padding: 0 11px;
                        height: 30px;">批量删除</el-button>
            <span style="color: #9A9CA1;font-size: 14px;">（最多可一次性添加10个片区）</span>
            <el-table
              ref="tableList"
              :data="form.aaumAddrDTOs"
              style="width: 100%; margin: 15px 0"
              border
            max-height="420"
              :stripe="true"
              @selection-change="handleSelectionChange"
            >
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
              <el-table-column
              label="序号"
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                prop="addrName"
                label="片区名称"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
              
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'aaumAddrDTOs.' + scope.$index + '.addrName'"
                    :rules="[
                      { required: true, message: '请输入片区名称', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      size="mini"
                      placeholder="请输入小区/村庄名称"
                      v-model="scope.row.addrName"
                      maxlength="80"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="addressName"
                label="地图定位"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'aaumAddrDTOs.' + scope.$index + '.addressName'"
                    :rules="[{ required: true, message: '请选择地址属性', trigger: 'change' }]"
                  >
                    <el-input
                      size="mini"
                      placeholder="请点击地图选择定位"
                      @focus="showModelFocus(scope.$index,scope.row.addressName,scope.row.lng,scope.row.lat)"
                      v-model="scope.row.addressName"
                      maxlength="80"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="companyId"
                label="排序（限制0-9999）"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'aaumAddrDTOs.' + scope.$index + '.sortNum'"
                    :rules="[{ required: true, message: '请选择所属分公司', trigger: 'change' }]"
                  >
                    <el-input
                      size="mini"
                      type="number"
                      @input="scope.row.sortNum= scope.row.sortNum>9999  ? 9999 : (( scope.row.sortNum< 0) ? 0: scope.row.sortNum)"
                      placeholder="请输入内容"
                      v-model.number="scope.row.sortNum"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="eliminate">清除配置项</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </div>
      </div>
    </div>
    <!-- 地图组件 -->
    <el-dialog
      :visible.sync="showModel"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <mapDialog @closeModal="closeModel" :addresname='addresname' :point='point'></mapDialog>
    </el-dialog>
  </div>
</template>

<script>
import {areaDropDownList,deptDropDownList,addrBatchInsert} from "@/RequestPort/induction";
import mapDialog from "./mapDialog";
import {deptDropDown,useraddrBatchInsert} from "../../RequestPort/peopleUser/people";
export default {
  props:{
    activeName:{
      type:String,
      default:'0'
    },
    pageParams:{
      type:Object,
      default: function(){
        return {}
      }
    }
  },
  components: { mapDialog },
  data() {
    return {
      form: {
        province:585,
        city:619,
        district:622,
        // 表格数据
        aaumAddrDTOs: [{
          id:0,
          addrName:'',
          addressName:'',
          sortNum:'0',
          lng:'',
          lat:''
        }],
      },
       // 地址下拉-省
      roleArray_group: [],
      // 市
      roleArray_city:[],
      // 区/县
      roleArray_area:[],
      // 部门下拉
      streetList:[],
      title: '',
      rules: {
        province: [{ required: true, message: '请选择省', trigger: 'change' }],
        city: { required: true, message: '请选择市', trigger: 'change' },
        district: { required: true, message: '请选择区域', trigger: 'change' },
        // town: { required: true, message: '请选择街道', trigger: 'change' },
        // addressAttr: { required: true, message: '请选择类型名称', trigger: 'change' },
        addrDeptid: { required: true, message: '请选择所所属部门', trigger: 'change' },
      },
      // 地图组件
      showModel:false,
      //添加数据
      numberID:0,
      // 暂存文件
      temporaryFile:[],
      // 地址
      addresname:'',
      pname:'',
      cname:'',
      sname:'',
      defaultStart:true,
      point:[]
    }
  },
  mounted() {
    let that = this
    // 省
    this.getRoleIdsDic()
    // 部门下拉
    // this.getDeptDropDownList()
    // this.getTreeList()
    // this.findBuildingTypeDict()
    // this.findfloorTypeDict()
    // this.findCmpOptions()
    setTimeout(() => {
      that.defaultdic()
    },200)
  },
  methods: {
    /**
     * 必填的星号
     */
    addRedStar(h,{ column}) {
      return [h('span',{style:'color:red'},'*'),h('span',' '+ column.label)]
    },
    /**
     * 勾选
     */
    handleSelectionChange(val){
      console.log(val)
      this.temporaryFile = val
    },
    /**
     * 点击打开地图
     */
    showModelFocus(index,addres,lng,lat){
      this.showModel = true
      this.modelIndex = index
      if(addres) {
        this.addresname = addres
        this.point = [lng,lat]
      }else{
        this.addresname  =this.pname+this.sname+this.cname
      }
    },
    /**
     *    功能描述： 地图选择位置
     *    开发人：why
     *    开时间：2022.5.16
     */
    closeModel(address, lnglat) {
      if (!address && !lnglat) {
        this.showModel = false;
      } else {
        this.form.aaumAddrDTOs[this.modelIndex].lat = lnglat[1];
        this.form.aaumAddrDTOs[this.modelIndex].lng = lnglat[0];
        this.form.aaumAddrDTOs[this.modelIndex].addressName =address;
        this.showModel = false;
        this.$forceUpdate();
      }
    },
    close(){
      this.addresname = ''
      this.$emit('close')
    },
    /**
     * 清除配置项
     */
    eliminate() {
      this.form = {
        // 表格数据
        aaumAddrDTOs: [{
          id:0,
          addrName:'',
          addressName:'',
          sortNum:'0'
        }],
      }
      this.numberID = 0
      this.$refs.subMap.clearDraws()
    },
    /**
     * 新增
     */
    addIncreased() {
      if(this.form.aaumAddrDTOs.length < 10) {
        this.numberID += 1
      this.form.aaumAddrDTOs.push({
          id:this.numberID,
          addrName:'',
          addressName:'',
          sortNum:'0'
        })
      } else {
        this.$message.error('最多10条')
      }
    },
    /**
     * 批量删除
     */
    delIncreased(){
      if(this.temporaryFile.length == 0) {
        this.$message.error('请先勾选数据')
        return
      }
      this.temporaryFile.forEach(el => {
        this.form.aaumAddrDTOs.forEach((item,index) =>{
          if(el.id ==item.id ){
            this.form.aaumAddrDTOs.splice(index,1)
          }
        })
      })
    },
    /**
     * 提交
     */
    save() {
      this.form.addrDeptid = this.pageParams.homeCode
      // 判斷一下
      if(this.activeName==1){
        if(this.form.aaumAddrDTOs.length == 0) {
          this.$message.error('至少添加一条片区信息')
          return
        }
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            addrBatchInsert(this.form).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.form = {
                  // 表格数据
                  aaumAddrDTOs: [{
                    id:0,
                    addrName:'',
                    addressName:'',
                    sortNum:''
                  }],
                }
                this.$emit('close')
              }
            })
          }
        })
      }else {
        if(this.form.aaumAddrDTOs.length == 0) {
          this.$message.error('至少添加一条片区信息')
          return
        }
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            useraddrBatchInsert(this.form).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.form = {
                  // 表格数据
                  aaumAddrDTOs: [{
                    id:0,
                    addrName:'',
                    addressName:'',
                    sortNum:''
                  }],
                }
                this.$emit('close')
              }
            })
          }
        })
      }
    },
    setMapClick() {
      this.$refs.subMap.getLocation()
    },
    /**
     * 部门下拉
     */
    getDeptDropDownList(){
      //判断是民用还是费民
      if(this.activeName==0){
        deptDropDown().then(res => {
          this.streetList = res.data
        }).catch(err => {
              this.streetList = []
            })
      }else{
        deptDropDownList().then(res => {
          this.streetList = res.data

        }).catch(err => {
              this.streetList = []
            })
      }
    },
    // 默认地址
    defaultdic(){
      this.getRoleIdsCity(this.form.province)
      setTimeout(() => {
        this.getRoleIdsDistrict(this.form.city)
      },200)
      setTimeout(() => {
        this.defaultStart = false
      },500)
      // console.log(this.roleArray_area,"roleArray_area")
    },
   /**
     * 地址联动下拉-省
     */
    async getRoleIdsDic() {
       await areaDropDownList({pid:0}).then(res => {
          this.roleArray_group = res.data
        })
        .catch(err => {
          this.roleArray_group = []
        })
    },
    /**
     * 地址联动下拉-市
     */
    async getRoleIdsCity(val) {
      if(this.pname){
        this.pname = ''
        this.sname = ''
        this.cname = ''
      }
      this.pname = this.roleArray_group.find(el => el.id == val).name
      if(this.form.city && !this.defaultStart) {
      this.form.city = ''
      }
      if(this.form.district && !this.defaultStart) {
      this.form.district = ''
      }
      await areaDropDownList({pid:this.form.province}).then(res => {
          this.roleArray_city = res.data
        })
        .catch(err => {
          this.roleArray_city = []
        })
    },
    /**
     * 地址联动下拉-区县
     */
    async getRoleIdsDistrict(val) {
      if(this.sname){
        this.sname = ''
        this.cname = ''
      }
      this.sname = this.roleArray_city.find(el => el.id == val).name
      if(this.form.district  && !this.defaultStart) {
      this.form.district = ''
      }
      await areaDropDownList({pid:this.form.city}).then(res => {
          this.roleArray_area = res.data
        })
        .catch(err => {
          this.roleArray_area = []
        })
    },
    /**
     * 下拉完选择地址
     */
    getRoleIdsdassname(val){
      if(this.cname){
        this.cname = ''
      }
      this.cname += this.roleArray_area.find(el => el.id == val).name
    }
  }
}
</script>
<style lang="scss" scoped>
.map-row {
  position: relative;
  .btn-draw {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.page {
  width: 100%;
  background: #fff;
  height:calc(100vh - 175px);
  ::v-deep{
    .el-form-item {
      margin-bottom: 0;
    }
    .colsite {
      margin-bottom: 20px;
    }
  }
  .create {
    width: 100%;
    // height: 58px;
    // border-bottom: 1px solid #ecedf1;
    // background-color: burlywood;
    // display: flex;
    // flex-direction: row;
    align-items: center;
    .create_size {
      font-size: 18px;
      color: #303133;
      font-weight: bold;
      margin-left: 20px;
      span{
        width: 3px;
        height: 16px;
        border:1px solid #1082FF;
        margin-right:9px;
      }
    }
  }
  .box {
    width: 96%;
    margin:20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;

      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        // border: 1px solid #ecedf1;
        // padding: 10px;
        margin-bottom: 20px;
        .title_box{
          display: flex;
          flex-direction: row;
          flex-wrap:wrap;
          .el-form-item{
            margin: 10px 0;
          }
        }
        
      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.img {
  height: 12px;
  border: 1px solid #3f74d5;
  margin-top: 6px;
  margin-right: 5px;
}
.titleName {
  font-size: 14px;
  padding-bottom: 15px;
  font-weight: 400;
  color: #303133;
}
.addBtn {
  margin: 0 10px;
}
.detil-code{
  margin-left: 20px;
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 20px;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
</style>
